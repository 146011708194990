<template>
    <v-btn small :style="theme" @click="generateCode()">Generate Code</v-btn>
</template>

<script>
    export default {
        props: {
            prefix: {
                type: String,
                default: ()=>''
            },
        },
        methods: {
            generateCode() {
                var code=this.prefix+btoa(Math.random()).substr(5,5).toUpperCase()
                this.$emit(this.appEvents.INPUT,code)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>