<template>
<div>
    <v-card class="display-block" flat>
        <h3>Modify Product</h3>
        <v-card-text>
            <alert-message v-if="msg" :error="msg"></alert-message>
            <v-alert dense v-if="success" type="success">Product Added Successfully</v-alert>
            <v-form ref="form" lazy-validation>
                <v-layout>
                    <v-flex sm10 mr-2>
                        <v-text-field label="Code*" :rules="productCodeRules" v-model="product.code" required></v-text-field>
                    </v-flex>
                    <v-flex sm2 pt-4>
                        <generate-code prefix="P-" v-model="product.code" />
                    </v-flex>
                </v-layout>
                <v-flex>
                    <v-text-field label="Title*" :rules="titleRules" v-model="product.title" required></v-text-field>
                </v-flex>
                <v-flex>
                    <label>Description</label>
                    <wysiwyg v-model="product.desc"></wysiwyg>
                </v-flex>
                <v-flex my-10>
                    <v-text-field label="Purchase Price" type="number" v-model.number="product.purchasePrice" required></v-text-field>
                </v-flex>
                <v-flex>
                    <v-text-field label="Price*" type="number" :rules="priceRules" v-model.number="product.price" required></v-text-field>
                </v-flex>
                <v-flex>
                    <v-text-field label="Offer Price" type="number" @input="checkPrice()" v-model.number="product.offerprice" :error="priceError" :error-messages="priceErrorMsg"></v-text-field>
                </v-flex>
                <v-flex>
                    <auto-complete @addButtonClicked='toggleBrandDialog' :showAddNewButton="true" v-model="product.brand" :items="brands" title="displayName" itemText="displayName" label="Brand" :value="brand"></auto-complete>
                    <app-dialog :show="showProduct" @close="showProduct=!showProduct">
                        <v-alert v-if="brandErrorMsg" :type="msgType">
                            <ul v-for="item in brandErrorMsg" :key="item.errors">
                                <li>{{item}}</li>
                            </ul>
                        </v-alert>
                        <v-form ref="addBrandform" lazy-validation>
                            <v-text-field label="Title" :rules="titleRules" v-model="newBrand.title" required></v-text-field>
                            <v-flex float-right>
                                <v-btn block :style="theme" @click="submitBrand()">Save</v-btn>
                            </v-flex>
                        </v-form>
                        <!-- <add-brand @update='updateProduct'></add-brand> -->
                    </app-dialog>
                </v-flex>
                <v-flex>
                    <v-autocomplete v-model="product.category" :items="category" item-text="displayName" label="Category" multiple return-object></v-autocomplete>
                </v-flex>
                <v-layout wrap>
                    <v-flex xs6 sm6>
                        <v-text-field label="Order to shown on webstore" type="number" v-model.number="product.displayOrder"></v-text-field>
                    </v-flex>
                    <v-flex xs4 sm3 mx-2>
                        <v-checkbox v-model="product.active" label="Active"></v-checkbox>
                    </v-flex>
                </v-layout>
                <v-flex>
                    <v-checkbox v-model="product.bestDeal" label="Show as Best Deal on WebStore"></v-checkbox>
                </v-flex>
                <v-layout wrap>
                    <v-flex xs12 sm4 md3 class="pa-1" v-for="(image, index) in product.images" :key="image.filename" width="200">
                        <video-card :src="image.path" @close="removeImage(index)" :showClose="true" v-if="image.mimetype.startsWith('video/')"></video-card>
                        <image-card :src="image.path" @close="removeImage(index)" :showClose="true" v-else></image-card>
                    </v-flex>
                </v-layout>
                <v-flex>
                    <h4>Add Product Image</h4><br>
                    <file-upload ref="fileupload" v-model="images" label="Upload Images" :multiple="true"></file-upload>
                    <v-progress-linear v-if="progress>0&&!msg" v-model="progress" color="orange" height="25">
                        <strong>Uploading Images {{ progress }}%</strong>
                    </v-progress-linear>
                </v-flex>
                <alert-message v-if="msg" :error="msg"></alert-message>
                <v-flex text-right>
                    <v-btn class="mr-4" :style="theme" :loading="loading" :disabled="loading" @click="submit()">Save</v-btn>
                    <v-btn v-if="id==0" class="mr-4" :style="theme" :loading="loading" :disabled="loading" @click="saveAndAddMore()">Submit and Add More</v-btn>
                    <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
                </v-flex>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import GenerateCode from '@/components/GenerateCode'
export default {
    components: {
        GenerateCode,
    },
    data() {
        return {
            product: this.defaultProduct(),
            images: [],
            brands: [],
            category: [],
            items: [],
            id: 0,
            uploadImages: [],
            titleRules: [
                v => !!v || 'Title Marks is required',
            ],
            priceRules: [
                v => !!v || 'Price is required',
            ],
            productCodeRules: [
                v => !!v || 'Product Code is required',
            ],
            priceError: false,
            priceErrorMsg: '',
            loading: false,
            showProduct: false,
            progress: 0,
            brand: {},
            newBrand: {
                title: "",
            },
            brandErrorMsg: "",
            originalCode: '',
            originalTitle: '',
            redirect: true,
            success: false,
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.product.vendorId = this.getUserProfile().vendorId
            this.brands = await this.getItem(appConstants.BRANDS_API + "/refList")
            this.category = await this.getItem(appConstants.CATEGORIES_API + "/categoriesForProduct")
            if (this.id != 0) {
                this.product = await this.getItem(appConstants.PRODUCTS_API + "/" + this.id)
                this.brand = this.product.brand
                this.originalCode = this.product.code,
                    this.originalTitle = this.product.title
            }
        },
        handleFileUpload() {
            if(this.$refs.images)
                this.images = this.$refs.images.files
        },
        async submit() {
            if (this.$refs.form.validate() && !this.priceError) {
                try {
                    this.loading = true
                    this.product.titleUpdated = (this.originalTitle != this.product.title)
                    this.product.codeUpdated = (this.originalCode != this.product.code)
                    let formData = this.convertToFormData(this.product, this.images, 'productImages')
                    if (this.id == 0)
                        await this.uploadFormData('post',formData)
                    else
                        await this.uploadFormData('put', formData)
                    this.loading = false
                    if (this.redirect)
                        this.$router.push("/app/products")
                    else {
                        this.success = true
                        this.$refs.form.reset()
                        this.product = this.defaultProduct()
                        this.$refs.fileupload.removeFile()
                        this.images = []
                        this.product.vendorId = this.getUserProfile().vendorId
                        this.redirect = true
                        setTimeout(() => { this.success = false }, 3000)
                    }
                } catch (error) {
                    console.log(error);
                    this.handleError(error)
                    this.msg = error.response.data
                    this.loading = false
                }
            }
        },
        removeImage(index) {
            this.product.images.splice(index, 1)
        },
        checkPrice() {
            if (parseInt(this.product.price) < parseInt(this.product.offerprice)) {
                this.priceError = true
                this.priceErrorMsg = "Offer Price cannot be greater than selling price"
            } else {
                this.priceError = false
                this.priceErrorMsg = ''
            }
        },
        toggleBrandDialog() {
            this.showProduct = !this.showProduct
        },
        updateProduct() {
            this.product.brand = this.convertToRefModel(this.newBrand._id, this.newBrand.title)
            this.brands.push(this.product.brand)
            this.brand = this.product.brand
            this.toggleBrandDialog()
        },
        async submitBrand() {
            if (this.$refs.addBrandform.validate()) {
                try {
                    this.newBrand.vendorId = this.$store.getters.vendor._id
                    this.newBrand = await this.postItem(appConstants.BRANDS_API, this.newBrand)
                    this.updateProduct()
                    alert("Brand Added")
                } catch (error) {
                    this.brandErrorMsg = error.response.data
                    this.msgType = "error"
                }
            }
        },
        saveAndAddMore() {
            this.redirect = false
            this.submit()
        },
        defaultProduct() {
            return {
                code: "",
                title: "",
                desc: "",
                price: 0,
                offerprice: 0,
                brand: {},
                category: [],
                purchasePrice: 0,
                images: "",
                active: true,
                codeUpdated: false,
                titleUpdated: false,
                displayOrder: Number,
                bestDeal:false,
                type:'SIMPLE'
            }
        },
        async uploadFormData(method,formData) {
            var config = {
                headers: this.commonHeader().headers,
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.progress = percentCompleted
                }
            }
            try{
                if(method=='post')
                    await this.postItem(appConstants.PRODUCTS_API, formData, config)
                else if(method=='put')
                    await this.putItem(appConstants.PRODUCTS_API+ "/" + this.product._id, formData, config)
            }
            catch(error){
                throw error
            }
        }
    }
}
</script>

<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
